/** @jsx jsx */
import { jsx, Styled } from "theme-ui";

export default () => (
  <section id="tables">
    <Styled.h2>Tables</Styled.h2>
    <Styled.table>
      <thead>
        <Styled.tr>
          <Styled.th>Heading</Styled.th>
          <Styled.th>Heading</Styled.th>
          <Styled.th>Heading</Styled.th>
          <Styled.th>Heading</Styled.th>
        </Styled.tr>
      </thead>
      <tbody>
        <Styled.tr>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
        </Styled.tr>
        <Styled.tr>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
        </Styled.tr>
        <Styled.tr>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
        </Styled.tr>
        <Styled.tr>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
        </Styled.tr>
        <Styled.tr>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
          <Styled.td>Cell</Styled.td>
        </Styled.tr>
      </tbody>
    </Styled.table>
  </section>
);
