/** @jsx jsx */
import { jsx, Styled, Button, Box } from 'theme-ui';

const Row = props => (
  <div
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      mx: -1,
      '& > div': {
        px: 1,
      },
    }}
  />
);

export default () => (
  <section id="buttons">
    <Styled.h2>Buttons</Styled.h2>
    <Box sx={{ mt: 4, mb: 2 }}>Primary button</Box>
    <Row>
      <div>
        <Button>Button</Button>
      </div>
      <div>
        <Button disabled={true}>Disabled</Button>
      </div>
    </Row>
    <Box sx={{ mt: 4, mb: 2 }}>Secondary button</Box>
    <Row>
      <div>
        <Button variant="secondary">Button</Button>
      </div>
      <div>
        <Button variant="secondary" disabled={true}>
          Disabled
        </Button>
      </div>
    </Row>
    {/*
    <Box sx={{ mt: 4, mb: 2 }}>Secondary button on dark background</Box>
    <div sx={{ bg: 'secondary', display: 'inline-flex', p: 3 }}>
      <Row>
        <div>
          <Button variant="light">Button</Button>
        </div>
        <div>
          <Button variant="light" disabled={true}>
            Disabled
          </Button>
        </div>
      </Row>
    </div>
    */}
    <Box sx={{ mt: 4, mb: 2 }}>Link button</Box>
    <Row>
      <div>
        <Button variant="plain">Link Button</Button>
      </div>
      <div>
        <Button variant="plain">Link Button &rarr;</Button>
      </div>
      <div>
        <Button variant="plain" disabled={true}>
          Disabled
        </Button>
      </div>
    </Row>
  </section>
);
