/** @jsx jsx */
import { jsx, Container, Box } from 'theme-ui';
import Layout from '../components/layout';
import Typography from './typography';
import Colors from './colors';
import Buttons from './buttons';
import Tables from './tables';

const Spacer = () => <Box sx={{ my: 5 }} />;

export default ({ pageContext }) => (
  <Layout
    title="Styled Guide"
    paths={pageContext.paths}
    locale={pageContext.locale || 'fi'}
  >
    <Container sx={{ py: [3, 4, 5] }}>
      <Colors />
      <Spacer />
      <Typography />
      <Spacer />
      <Buttons />
      <Spacer />
      <Tables />
    </Container>
  </Layout>
);
